import { useState, useCallback, useEffect } from "react";

import cn from "classnames";

import { MdKeyboardArrowDown, MdOutlineLanguage } from "react-icons/md";
import { ArrowContainer, Popover, PopoverPosition } from "react-tiny-popover";

import { useSelector, useDispatch } from "react-redux";

import { useIntl } from "react-intl";

import { changeLanguage } from "redux/actions/globalSlice/globalSlice";
import { languages_enum } from "redux/actions/globalSlice";

import { RootState } from "redux/store";
import { LanguageChangerPopoverItem } from "./LanguageChangerPopoverItem";

import "./LanguageChanger.scss";

interface LanguageChangerPorps {
  className?: string;
  position?: PopoverPosition;
  mobile?: boolean;
}

export function LanguageChanger({
  className = "language-changer",
  position = "bottom",
  mobile
}: LanguageChangerPorps) {
  const dispatch = useDispatch();

  const { formatMessage } = useIntl();

  const { language } = useSelector((state: RootState) => state.global);

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [parent, setParent] = useState(document.body);

  useEffect(() => {
    setParent(document.getElementById(className)!);
  }, [document.getElementById(className)!]);

  const setNewLanguage = useCallback(
    (language: languages_enum) => {
      dispatch(changeLanguage(language));
      setIsPopoverOpen(false);
    },
    [dispatch]
  );

  const languages = [
    {
      icon: "https://aversiclinic.ge/images/flags/en.png",
      id: "languages.changer",
      defaultMessage: "_english_",
      language: languages_enum.ENGLISH
    },
    // {
    //   icon: "https://aversiclinic.ge/images/flags/ru.png",
    //   id: "languages.changer",
    //   defaultMessage: "_russian_",
    //   language: languages_enum.RUSSIAN
    // },
    {
      icon: "https://aversiclinic.ge/images/flags/ka.png",
      id: "languages.changer",
      defaultMessage: "_georgian_",
      language: languages_enum.GEORGIAN
    }
  ];
  if (window.location.pathname !== "/") {
    return null;
  }
  return (
    
    <Popover
      parentElement={parent}
      isOpen={isPopoverOpen}
      positions={[position]}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={""}
          arrowSize={15}
          arrowClassName="language-changer-popover-arrow"
        >
          <div className="language-changer-popover-content">
            {languages
              .filter((item) => item.language !== language)
              .map((lang, i) => {
                return (
                  <LanguageChangerPopoverItem
                    key={i}
                    icon={lang.icon}
                    text={formatMessage(
                      {
                        id: lang.id,
                        defaultMessage: lang.defaultMessage
                      },
                      { language: lang.language }
                    )}
                    onClick={() => {
                      setNewLanguage(lang.language);
                    }}
                  />
                );
              })}
          </div>
        </ArrowContainer>
      )}
      reposition={false}
      onClickOutside={() => setIsPopoverOpen(false)}
    >
      <div
        className={cn(className, "language-changer")}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        id={className}
      >
        <MdOutlineLanguage
          className="language-changer__icon"
          onClick={() => mobile && setIsPopoverOpen(!isPopoverOpen)}
        />
        {!mobile && (
          <>
            <p className="language-changer__language">
              {formatMessage(
                {
                  id: "languages.changer",
                  defaultMessage: "_english_"
                },
                { language }
              )}
            </p>
            <MdKeyboardArrowDown className="language-changer__expand" />
          </>
        )}
      </div>
    </Popover>
  );
}
