import cn from "classnames";

import "./LanguageChangerPopoverItem.scss";

interface LanguageChangerPopoverItemProps {
  icon?: string;
  text: string;
  onClick?: () => void;
  hidden?: boolean;
}

export function LanguageChangerPopoverItem({
  icon,
  text,
  onClick,
  hidden,
}: LanguageChangerPopoverItemProps) {
  if (hidden) return <></>;
  return (
    <div className={cn("langauge-changer-popover-item")} onClick={onClick}>
      {/* <div className="langauge-changer-popover-item__icon">
        <img src={icon} alt="language-icon" />
      </div> */}
      <p className="langauge-changer-popover-item__text">{text}</p>
    </div>
  );
}
