import cn from "classnames";

import { ReactComponent as AversiLogo } from "assets/images/aversi-logo.svg";

import { useIntl } from "react-intl";
import { NavItem } from "components/NavItem";
import { SearchInput } from "components/SearchInput";

import { useScrolledIndicator, useScrollStopedIndicator } from "hooks";
import { Link, useLocation } from "react-router-dom";

import { useHeaderNavigation } from "hooks";

import "./NavigationDesktop.scss";
import { LanguageChanger } from "components/LanguageChanger";
import { NavigateToFirstPage } from "../../../utils/NavigateToFistPage";
import { useWindowSize } from "rooks";
import { NavProfile } from "components/NavProfile/NavProfile";
import { AuthContext } from "providers/AuthProvider/context/AuthContext";
import { useContext } from "react";

export const NavigationDesktop = () => {
  const { formatMessage } = useIntl();
  const { innerWidth } = useWindowSize();

  const { scrolled } = useScrolledIndicator();
  const { scrolling } = useScrollStopedIndicator();

  const { headerNavigation } = useHeaderNavigation();
  const { authorizationStage } = useContext(AuthContext);

  return (
    <div
      className={cn("navigation", {
        ["navigation-scrolled"]: scrolled,
        ["navigation-scrolling"]: scrolling === true && scrolled === true,
      })}
    >
      <Link className="navigation__left" aria-label="Aversi Clinic" to="/">
        <AversiLogo onClick={() => (window.location.href = "/")} />
      </Link>
      <div className="navigation__center">
        {headerNavigation &&
          innerWidth &&
          headerNavigation.map((navigationItem: any, index: number) => {
            if (navigationItem) {
              const hasChildren =
                navigationItem?.children &&
                navigationItem?.children.length !== 0;

              if (innerWidth < 1300 ? index === 5 : index === 6) {
                return (
                  <NavItem
                    target={navigationItem?.target}
                    key={navigationItem?.id}
                    popoverContent={[
                      ...headerNavigation
                        .slice(innerWidth < 1300 ? 6 : 7)
                        ?.map((child: any) => {
                          return (
                            <NavItem
                              target={child?.target}
                              key={child?.key}
                              to={
                                NavigateToFirstPage(child?.value)
                                  ? child?.value + "/1"
                                  : child?.value
                              }
                            >
                              {child?.name}
                            </NavItem>
                          );
                        }),
                      ...navigationItem?.children?.map((child: any) => {
                        return (
                          <NavItem
                            target={child?.target}
                            key={child?.key}
                            to={
                              NavigateToFirstPage(child?.value)
                                ? child?.value + "/"
                                : child?.value
                            }
                          >
                            {child?.name}
                          </NavItem>
                        );
                      }),
                    ]}
                    className="navigation__center-item"
                    hasChildren={hasChildren}
                    to={
                      NavigateToFirstPage(navigationItem?.value)
                        ? navigationItem?.value + "/"
                        : navigationItem?.value
                    }
                  >
                    {navigationItem?.name}
                  </NavItem>
                );
              }
              return (
                <NavItem
                  target={navigationItem?.target}
                  key={navigationItem?.id}
                  popoverContent={navigationItem?.children?.map(
                    (child: any) => {
                      return (
                        <NavItem
                          target={child?.target}
                          key={child?.key}
                          to={
                            NavigateToFirstPage(child?.value)
                              ? child?.value + "/"
                              : child?.value
                          }
                        >
                          {child?.name}
                        </NavItem>
                      );
                    }
                  )}
                  className={cn("navigation__center-item", {
                    "navigation__center-item--disabled": hasChildren,
                  })}
                  hasChildren={hasChildren}
                  to={
                    NavigateToFirstPage(navigationItem?.value)
                      ? navigationItem?.value + "/"
                      : navigationItem?.value
                  }
                >
                  {navigationItem?.name}
                </NavItem>
              );
            } else {
              return null;
            }
          })}
      </div>
      <div className="navigation__right">
        {authorizationStage !== "authorized" && (
          <div className="navigation__right-auth">
            <Link to="/login">
              {formatMessage({
                id: "header.nav-login",
                defaultMessage: "_login_",
              })}
            </Link>
            <Link to="/registration">
              {formatMessage({
                id: "header.nav-registration",
                defaultMessage: "_register_",
              })}
            </Link>
          </div>
        )}
        {/* <SearchInput /> */}
        {authorizationStage === "authorized" && <NavProfile />}
        <LanguageChanger />
      </div>
      <div className="navigation-animation" />
    </div>
  );
};
