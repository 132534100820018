import cn from "classnames";
import { useContext, useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";

import { NavigationMobileLink, Accordion } from "components";
import { NavigationMobileContent } from "./NavigationMobileContent";

import {
  useHeaderNavigation,
  useScrollStopedIndicator,
  useScrolledIndicator,
} from "hooks";

import { ReactComponent as AversiLogo } from "assets/icons/aversi-logo.svg";
import { ReactComponent as LoginIcon } from "assets/icons/login-icon.svg";
import { ReactComponent as RegistrationIcon } from "assets/icons/registration-icon.svg";
import { SearchInput } from "components/SearchInput";
import { convertIconToReactNode } from "utils/convertIconToReactNode";

import {
  AuthContext,
  AuthorizationStage,
} from "providers/AuthProvider/context/AuthContext";
import { AiFillHome, AiFillSetting } from "react-icons/ai";
import { IoMdNotifications } from "react-icons/io";
import { MdLogout, MdPayment } from "react-icons/md";
import { RiCalendarCheckFill, RiFlaskFill } from "react-icons/ri";
import { NavigateToFirstPage } from "../../../utils/NavigateToFistPage";
import closeImg from "../../../assets/icons/icon-close.svg";
import { LocalStorage } from "config/keys";
import "./NavigationMobile.scss";
import { NavigationMobileItem } from "./NavigationMobileItem";
import { BsExclamationOctagonFill } from "react-icons/bs";
import { LanguageChanger } from "components/LanguageChanger";

interface NavigationMobileProps {
  hideSearch?: boolean;
}

export const NavigationMobile = ({ hideSearch }: NavigationMobileProps) => {
  const { formatMessage } = useIntl();
  const { userData, setToken, authorizationStage } = useContext(AuthContext);
  const [mainPagesIsOpen, setMainPagesIsOpen] = useState<boolean>(false);
  const [profileInfo, setProfileInfo] = useState(userData);
  const { scrolled } = useScrolledIndicator();
  const { scrolling } = useScrollStopedIndicator();

  const { headerNavigation } = useHeaderNavigation();
  // change on line 42 true to false for showing first hero on main page (before it was navigation list)
  const [showContent, setShowContent] = useState<boolean>(false);

  const navigate = useNavigate();
  const body = document.body;

  useEffect(() => {
    localStorage.getItem("userData") &&
      // @ts-ignore
      setProfileInfo(JSON.parse(localStorage.getItem("userData")));
  }, [localStorage.getItem("userData")]);

  return (
    <>
      {/* changing bg-color in header with style expresion on line 52 */}
      <div
        className={cn("navigation-mobile", {
          ["navigation-scrolled"]: scrolled,
          ["navigation-scrolling"]: scrolling === true && scrolled === true,
        })}
        // style={
        //   showContent
        //     ? { backgroundColor: "white" }
        //     : { backgroundColor: "transparent" }
        // }
      >
        <Link className="navigation-mobile__left" to="/">
          <AversiLogo />
        </Link>
        <div className="navigation-mobile__right">
          {/* add !showcontent on line 56 */}
          {!showContent ? <LanguageChanger mobile /> : null}
          {/* chenge "!hidesearch &&" with "!showContent" on line 58 */}
          {/* {!showContent ? <SearchInput /> : null} */}
          {/* 59 line - add ternary for showing default picture */}
          {!showContent ? (
            <GiHamburgerMenu
              onClick={() => {
                setShowContent((val) => !val);
                //commented line 73, it blocked scrolling
                // body.classList.toggle("fixed_body");
              }}
              // 64 line - insert img tag, it needs to move styles in Scss
            />
          ) : (
            <img
              className="close"
              onClick={() => setShowContent((val) => !val)}
              src={closeImg}
              alt="closingIcon"
              loading="lazy"
            />
          )}
        </div>
        <div className="navigation-animation"></div>
      </div>
      <NavigationMobileContent active={showContent}>
        {authorizationStage === AuthorizationStage.AUTHORIZED && (
          <div className="navigation-mobile-personal">
            <div className="navigation-mobile-personal__info">
              <div className="navigation-mobile-personal__info-avatar">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/480px-User-avatar.svg.png"
                  alt="avatar"
                  loading="lazy"
                />
              </div>
              <div className="navigation-mobile-personal__info-name">
                {profileInfo?.name}
                &nbsp;
                {profileInfo?.last_name}
              </div>
            </div>
            <div className="navigation-mobile-personal__actions">
              <div
                onClick={() => {
                  body.classList.remove("fixed_body");
                  navigate("/pr-settings");
                  setShowContent(false);
                }}
                className="navigation-mobile-personal__actions-settings"
              >
                <AiFillSetting />
              </div>
              <div
                className="navigation-mobile-personal__actions-logout"
                onClick={() => {
                  localStorage.removeItem(LocalStorage.AUTH_TOKEN);
                  setToken(null);
                  navigate("/");
                }}
              >
                <MdLogout />
              </div>
            </div>
          </div>
        )}
        <div className="navigation-mobile-links">
          {authorizationStage === AuthorizationStage.AUTHORIZED && (
            <div
              className={cn("navigation-mobile-personal-pages", {
                ["navigation-mobile-is-auth"]:
                  authorizationStage === AuthorizationStage.AUTHORIZED,
              })}
            >
              <div className="navigation-mobile-header">
                <FormattedMessage
                  id="header.nav-my-pages"
                  defaultMessage="_my-pages_"
                />
              </div>
              {/* <NavigationMobileLink
                onClick={() => {
                  body.classList.remove("fixed_body");
                  navigate("/pr-visit-doctor/current");
                  setShowContent(false);
                }}
                icon={<AiFillHome />}
              >
                <FormattedMessage
                  id="private.sidebar.personal-page"
                  defaultMessage="_personal-page_"
                />
              </NavigationMobileLink> */}
              <NavigationMobileLink
                onClick={() => {
                  body.classList.remove("fixed_body");
                  navigate("/pr-visit-doctor/current");
                  setShowContent(false);
                }}
                icon={<RiCalendarCheckFill />}
              >
                <FormattedMessage
                  id="private.sidebar.visit-doctor"
                  defaultMessage="_visit-doctor_"
                />
              </NavigationMobileLink>
              {/* <NavigationMobileLink
                onClick={() => {
                  body.classList.remove("fixed_body");
                  navigate("/pr-analysis");
                  setShowContent(false);
                }}
                icon={<RiFlaskFill />}
              >
                <FormattedMessage
                  id="private.sidebar.analysis"
                  defaultMessage="_analysis_"
                />
              </NavigationMobileLink> */}
              {/* <NavigationMobileLink
                onClick={() => {
                  body.classList.remove("fixed_body");
                  navigate("/pr-notifications");
                  setShowContent(false);
                }}
                icon={<IoMdNotifications />}
              >
                <FormattedMessage
                  id="private.sidebar.notifications"
                  defaultMessage="_notifications_"
                />
              </NavigationMobileLink> */}
              {/* <NavigationMobileLink
                onClick={() => {
                  body.classList.remove("fixed_body");
                  navigate("pr-unsatisfaction");
                  setShowContent(false);
                }}
                icon={<BsExclamationOctagonFill />}
              >
                <FormattedMessage id="private.unsatisfaction.header" />
              </NavigationMobileLink> */}
              {/* <NavigationMobileLink
                onClick={() => {
                  body.classList.remove("fixed_body");
                  navigate("/pr-payments");
                  setShowContent(false);
                }}
                icon={<MdPayment />}
              >
                <FormattedMessage
                  id="private.sidebar.payments"
                  defaultMessage="_payments_"
                />
              </NavigationMobileLink> */}
            </div>
          )}

          <div
            className={cn("navigation-mobile-main-pages", {
              ["navigation-mobile-is-auth"]:
                authorizationStage === AuthorizationStage.AUTHORIZED,
            })}
          >
            {authorizationStage === AuthorizationStage.AUTHORIZED && (
              <Accordion
                title={
                  <div className="navigation-mobile-header">
                    <FormattedMessage
                      id="header.nav-main-pages"
                      defaultMessage="_main-pages_"
                    />
                  </div>
                }
                open={mainPagesIsOpen}
                onChange={(e) => setMainPagesIsOpen(e)}
              >
                {headerNavigation?.map((navigationItem: any, i: number) => {
                  return navigationItem?.children?.length > 0 ? (
                    <NavigationMobileItem
                      title={navigationItem?.name}
                      icon={convertIconToReactNode(navigationItem?.data?.icon)}
                      key={i}
                    >
                      {navigationItem.children.map((item: any) => {
                        return (
                          <NavigationMobileLink
                            key={item.id}
                            onClick={() => {
                              body.classList.remove("fixed_body");
                              navigate(
                                NavigateToFirstPage(item?.value)
                                  ? item?.value + "/"
                                  : item?.value
                              );

                              setShowContent(false);
                            }}
                            icon={convertIconToReactNode(item?.data?.icon)}
                          >
                            {item?.name}
                          </NavigationMobileLink>
                        );
                      })}
                    </NavigationMobileItem>
                  ) : (
                    <NavigationMobileLink
                      key={navigationItem.id}
                      onClick={() => {
                        body.classList.remove("fixed_body");
                        navigate(
                          NavigateToFirstPage(navigationItem?.value)
                            ? navigationItem?.value + "/"
                            : navigationItem?.value
                        );

                        setShowContent(false);
                      }}
                      icon={convertIconToReactNode(navigationItem?.data?.icon)}
                    >
                      {navigationItem?.name}
                    </NavigationMobileLink>
                  );
                })}
              </Accordion>
            )}
            {authorizationStage === AuthorizationStage.UNAUTHORIZED && (
              <>
                <NavigationMobileLink
                  onClick={() => {
                    body.classList.remove("fixed_body");
                    navigate("/registration");
                    setShowContent(false);
                  }}
                  icon={<RegistrationIcon />}
                >
                  {formatMessage({
                    id: "header.nav-registration",
                    defaultMessage: "_register_",
                  })}
                </NavigationMobileLink>
                <NavigationMobileLink
                  onClick={() => {
                    body.classList.remove("fixed_body");
                    navigate("/login");
                    setShowContent(false);
                  }}
                  icon={<LoginIcon />}
                >
                  {formatMessage({
                    id: "header.nav-login",
                    defaultMessage: "_login_",
                  })}
                </NavigationMobileLink>

                {headerNavigation?.map((navigationItem: any, i: number) => {
                  return navigationItem?.children?.length > 0 ? (
                    <NavigationMobileItem
                      key={i}
                      title={navigationItem?.name}
                      icon={convertIconToReactNode(navigationItem?.data?.icon)}
                    >
                      {navigationItem.children.map((item: any) => {
                        return (
                          <NavigationMobileLink
                            key={item.id}
                            onClick={() => {
                              body.classList.remove("fixed_body");
                              navigate(
                                NavigateToFirstPage(item?.value)
                                  ? item?.value + "/"
                                  : item?.value
                              );

                              setShowContent(false);
                            }}
                            icon={convertIconToReactNode(item?.data?.icon)}
                          >
                            {item?.name}
                          </NavigationMobileLink>
                        );
                      })}
                    </NavigationMobileItem>
                  ) : (
                    <NavigationMobileLink
                      key={navigationItem.id}
                      onClick={() => {
                        body.classList.remove("fixed_body");
                        navigate(
                          NavigateToFirstPage(navigationItem?.value)
                            ? navigationItem?.value + "/"
                            : navigationItem?.value
                        );

                        setShowContent(false);
                      }}
                      icon={convertIconToReactNode(navigationItem?.data?.icon)}
                    >
                      {navigationItem?.name}
                    </NavigationMobileLink>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </NavigationMobileContent>
    </>
  );
};
